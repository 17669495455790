import Box from "../../../common/components/Box";
import Heading from "../../../common/components/Heading";
import Text from "../../../common/components/Text";
import Container from "../../../common/components/UI/Container";
import { REGISTER_FORM_DATA } from "../../../common/data/SecD3v";
import React, { useState } from "react";
import RegisterFormArea from "./registerForm.style";
import { Row, Col, Form } from "./registerForm.style";
import Register from "../Register";

const RegisterForm = () => {
  const { blockTitle } = REGISTER_FORM_DATA;
  return (
    <RegisterFormArea id="register-form">
      <Container>
        <Form>
          <Row className="rowContents">
            <Col className="blockTitle">
              <Row>
                <Heading as="h2" content={blockTitle.MainTitle} />
                <Text as="p" content={blockTitle.text} />
              </Row>
            </Col>
            <Col className="blockTitleForm">
              <Register />
            </Col>
          </Row>
        </Form>
      </Container>
    </RegisterFormArea>
  );
};

export default RegisterForm;
