import Box from "../../../../common/components/Box";
import Heading from "../../../../common/components/Heading";
import Container from "../../../../common/components/UI/Container";
import { ENQUIRY_DATA } from "../../../../common/data/SecD3v";
import React, { useState } from "react";
import KeyBenefitsArea from "./section.style";
import RegisterForm from "../../../SecD3v/RegisterForm";

const SectionArea = () => {
  const { blockTitle, post } = ENQUIRY_DATA;
  const { MainTitle, title } = blockTitle;

  const [open, setOpen] = useState(false);
  const onOpenModal = () => functionA();
  const onCloseModal = () => setOpen(false);

  const [benefitValue, setBenefitValue] = useState("");

  const retrieveBenefits = (title, description) => {
    setBenefitValue({ title, description });
    setOpen(true);
  };

  return (
    <KeyBenefitsArea id="enquiry">
      <RegisterForm />
    </KeyBenefitsArea>
  );
};

export default SectionArea;
