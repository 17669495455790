import styled from "styled-components";

const RegisterFormArea = styled.section`
  background-color: #3b3f53;

  padding-bottom: 50px;
  padding-top: 50px;
  @media (max-width: 1600px) {
  }
  @media (max-width: 768px) {
    padding-bottom: 0;
    padding-top: 80px;
  }
  @media (max-width: 375px) {
    padding-top: 40px;
    padding-bottom: 255px;
  }

  .blockTitleForm {
    max-width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    justify-content: center;
    align-items: center; /* <---- NEW    */

    @media (max-width: 1600px) {
      // margin-bottom: 40px;
    }
    h2 {
      text-align: center;
      font-weight: bold;
      font-size: 36px;
      line-height: 1.31;
      letter-spacing: -0.5px;
      color: #252525;
      text-align: left;
      margin: 0;
      margin-bottom: 19px;
      @media (max-width: 1600px) {
        font-size: 30px;
        line-height: 1.3;
        max-width: 468px;
      }
      @media (max-width: 768px) {
        font-size: 28px;
      }
      @media (max-width: 375px) {
        font-size: 22px;
      }
    }
    p {
      margin: 0;
      font-weight: normal;
      font-size: 16px;
      line-height: 2;
      color: #252525;
      text-align: left;
      @media (max-width: 768px) {
        font-size: 15px;
      }
    }
  }

  .blockTitle {
    max-width: 100%;
    padding-top:20px
    padding-bottom: 20px;
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    justify-content: center;
    align-items: center; /* <---- NEW    */

    @media (max-width: 1600px) {
      // margin-bottom: 40px;
    }
    h2 {
      text-align: center;
      font-weight: bold;
      font-size: 36px;
      line-height: 1.31;
      letter-spacing: -0.5px;
      color: #252525;
      text-align: left;
      margin: 0;
      margin-bottom: 19px;
      @media (max-width: 1600px) {
        font-size: 30px;
        line-height: 1.3;
        max-width: 468px;
      }
      @media (max-width: 768px) {
        font-size: 28px;
      }
      @media (max-width: 375px) {
        font-size: 22px;
      }
    }
    p {
      margin: 0;
      font-weight: normal;
      font-size: 16px;
      line-height: 2;
      color: #252525;
      text-align: left;
      @media (max-width: 768px) {
        font-size: 15px;
      }
    }
  }
  .postWrap {
    display: flex;
    flex-wrap: wrap;
    margin-top: 65px;
    @media (max-width: 768px) {
      justify-content: center;
    }
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
    gap: 20px;
  }

  .grid-item {
    background-color: #ffffff;
    border-radius: 12px;
    border: 4px solid rgba(25, 177, 178, 0.25);
    padding: 10px;
    text-align: center;
  }

  /* Define styles for different screen sizes */
  @media screen and (max-width: 768px) {
    .grid-container {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
  }

  @media screen and (max-width: 480px) {
    .grid-container {
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    }
  }

  .post {
    flex: 0 0 25%;
    text-align: center;
    padding: 10px;
    @media (max-width: 768px) {
      flex: 0 0 28%;
      margin-bottom: 50px;
    }
    @media (max-width: 425px) {
      flex: 1 1 50%;
    }
    img {
      margin-left: auto;
      margin-right: auto;
    }
    h3 {
      font-weight: bold;
      font-size: 17px;
      line-height: 1.8;
      text-align: left;
      color: #252525;
      margin: 0;
      margin-top: 30px;
      @media (max-width: 1600px) {
        font-size: 16px;
        margin: 20px;
      }
    }
    &:hover {
      .feature-image-box-inner {
        transform: translateY(-5px);
        &:before {
          opacity: 1;
          transform: translateY(5px);
        }
      }
    }
  }
  .feature-image-box {
    text-align: left;
  }
  .feature-image-box-inner {
    transition: all 0.4s ease;
    display: inline-flex;
    &:before {
      pointer-events: none;
      position: absolute;
      z-index: -1;
      content: "";
      top: 100%;
      left: 5%;
      height: 10px;
      width: 90%;
      opacity: 0;
      background: radial-gradient(
        ellipse at center,
        rgba(132, 159, 184, 0.35) 0%,
        rgba(0, 0, 0, 0) 80%
      );
      transition-duration: 0.3s;
      transition-property: transform, opacity;
    }
  }

  .feature-text-box {
    font-size: 14px;
    color: #777777;
    text-align: left;
  }
  .benefits-icon {
    width: 70px;
    margin-top: 15px;
    text-align: left;
  }
  .customOverlay {
    background: rgba(36, 123, 160, 0.7);
  }
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Col = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  @media (max-width: 667px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

export const Form = styled.form`
  background-color: #f3f3f3;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  .rowContents {
  }
`;

export default RegisterFormArea;
